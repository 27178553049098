import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import './LandingPageContent.css';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import ApiSender from './common/ApiLogic';

function LandingPageHeaderFlow({ ti, ts }) {

  //const gatewayBaseUrl = "http://localhost:8002";
  const location = useLocation();
  const gatewayBaseUrl = "https://iraqgtw.kid-security.io";
  const [goalId, setGoalId] = useState(null);
  const [goalName, setGoalName] = useState(null);
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    const apiSender = new ApiSender();
    const checkUserResult = await apiSender.checkUser();
    if (!checkUserResult.success) {
      window.location.href = `http://subscribe.iq-gameland.com/lp/pin?${urlParams}`;
    }
  }

  useEffect(() => {
    if (queryParams.has("campaignId")) {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_GOOGLE_BASE_URL + 'campaign/info?uuid=' + queryParams.get("campaignId"),
            {
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                'Accept-Encoding' : 'gzip'
              }
            });
          if (response.ok) {
            const data = await response.json();
            const status = data.status;
            if (status) {
              setGoalId(data.goalId);
              setGoalName(data.goalName);
            }
          }
        }
        catch (error) {
        }
      }
      fetchData();
    }
  }, []);

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  useEffect(() => {
    const response = fetch('http://3.28.103.143:8085/api/v1/lpPageVisitHe',
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Accept-Encoding' : 'gzip'
        },
        body: JSON.stringify({
          campaign_id: queryParams.get("campaignId"),
          placement: queryParams.get("placement"),
          gclid: queryParams.get("gclid"),
          utm_campaign: queryParams.get("utm_campaign"),
          utm_source: queryParams.get("utm_source")
        })
      });
  }, [queryParams]);

  useEffect(() => {
    if (queryParams.has("campaignId")) {
      const fetchData = async () => {
        try {
          const response = await fetch(gatewayBaseUrl + '/game/campaign',
            {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                'Accept-Encoding' : 'gzip'
              },
              body: JSON.stringify({
                transactionId: ti,
                campaignId: queryParams.get("campaignId"),
                trackerId: queryParams.get("trackerId")
              })
            });
          if (response.ok) {
            const data = await response.json();
            const status = data.status;
            if (status) {
              const he_api_base = 'http://www.social-sms.com/iq-dcb/HE/v1.3/oneclick/sub.php';
              const serviceId = 721;
              const spId = 203;
              const shortcode = 3368;
              const servicename = 'gameland';
              const merchantname = 'strongbits';
              const redirectionUrl = `${he_api_base}?serviceId=${serviceId}&spId=${spId}&shortcode=${shortcode}&ti=${ti}&ts=${ts}&servicename=${servicename}&merchantname=${merchantname}`;
              window.location.href = redirectionUrl;
            }
          }
        }
        catch (error) {

        }
      }
      fetchData();
    }
  }, [queryParams]);

  useEffect(() => {
    // After the component mounts, append the noscript iframe to the body
    const noscriptIframe = document.createElement('noscript');
    noscriptIframe.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQNBQS6L"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.prepend(noscriptIframe);
  }, []);

  //OTHER
  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(true);
  const handleButtonClick = () => {
    setIsGradientRightToLeft(!isGradientRightToLeft);
    setIsImageVisible(!isImageVisible);
  };
  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #EE8031 50%, #F5C343 50%)'
      : 'linear-gradient(to right, #EE8031 50%, #F5C343 50%)',
  };

  //VARIABLES
  const [campaignId, setCampaignId] = useState(null);
  const [trackerId, setTrackerId] = useState(null);
  const [google, setGoogle] = useState(null);
  const [mfid, setMfid] = useState(null);
  const [mfToken, setMfToken] = useState(null);
  
  const params = {};
  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }
  useEffect(() => {
    if (queryParams.get("campaignId")) {
      setCampaignId(queryParams.get("campaignId"));
    }
    if (queryParams.get("trackerId")) {
      setTrackerId(queryParams.get("trackerId"));
    }
    if (queryParams.get("gclid")) {
      setGoogle(queryParams.get("gclid"));
    }
    if (queryParams.get("mfid")) {
      setMfid(queryParams.get("mfid"));
    }
    if (queryParams.get("mf_token")) {
      setMfToken(queryParams.get("mf_token"));
    }
  }, [queryParams]);

  const subscribeRedirection = () => {
    saveHistory();
  };

  //SAVE HISTORY FLOW
  const saveHistory = async () => {
    try {
      const date = new Date();
      const formattedDateTime = format(date, 'yyyy-MM-dd HH:mm:ssxxx');
      const gbraid = queryParams.get("gbraid") ? queryParams.get("gbraid") : null;
      const wbraid = queryParams.get("wbraid") ? queryParams.get("wbraid") : null;  
      const response = await fetch(gatewayBaseUrl + '/game/subscribe',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            'Accept-Encoding' : 'gzip'
          },
          body: JSON.stringify({
            // msisdn: msisdn,
            transactionId: ti,
            campaignId: campaignId,
            trackerId: trackerId,
            googleTrackerId: google,
            mfId: mfid,
            mfToken: mfToken,
            gbraid: gbraid,
            wbraid: wbraid,
            conversionDateTime: formattedDateTime,
            conversionGoalId: goalId,
            conversionGoalName: goalName,
            landingPageUrl: window.location.origin + location.pathname,
            landingPageName: "GL Main LP",
            p1: params.p1,
            p2: params.p2,
            p3: params.p3,
            p4: params.p4,
            p5: params.p5,
            p6: params.p6,
            p7: params.p7,
            p8: params.p8,
            p9: params.p9,
            p10: params.p10
          })
        });
      if (response.ok) {
        const he_api_base = 'http://www.social-sms.com/iq-dcb/HE/v1.3/oneclick/sub.php';
        const serviceId = 721;
        const spId = 203;
        const shortcode = 3368;
        const servicename = 'gameland';
        const merchantname = 'strongbits';
        const redirectionUrl = `${he_api_base}?serviceId=${serviceId}&spId=${spId}&shortcode=${shortcode}&ti=${ti}&ts=${ts}&servicename=${servicename}&merchantname=${merchantname}`;
        window.location.href = redirectionUrl;
      }
    }
    catch (error) {
    }
  };


  return (

    <div className="App">
      <Helmet>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TQNBQS6L');`}</script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-9FNXJWHV35"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-9FNXJWHV35');`}
        </script>
      </Helmet>

      <div className="upside" >
        <div className="header">
          <div className="logo">
            {
              isImageVisible ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../gameland-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../gameland-english.png' />)
            }
          </div>
          <div className="language" style={gradientStyle} onClick={handleButtonClick}>
            <p>عر</p>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
            <p>en</p>
          </div>
        </div>

        <div className="title">
          {
            isImageVisible ? (<h1>اكتشف 100 لعبة مميزة: عالم ينتظرك مليء بالإثارة والمغامرة والتحدي</h1>) : (<h1>100+ Premium Games: Unleash your inner hero in worlds of adventure, challenge, and excitement</h1>)
          }
          <div className="photo">
            <img alt='' loading='lazy' src='%PUBLIC_URL%/../../gameland.png' />
          </div>
        </div>
      </div>



      <div className="content">
        <div className="input">
          {
            isImageVisible ? (<button id="subscribe" className="lighting-vibration-button" onClick={subscribeRedirection}>إشترك</button>) : (<button id="subscribe" className="lighting-vibration-button" onClick={subscribeRedirection}>Subscribe</button>)
          }
        </div>
        <div className="terms" style={{ direction: "rtl" }}>
          {
            isImageVisible ? (<p>سيحصل المستخدمون لأول مرة على تجربة مجانية ليوم واحد سعر الخدمة 240 IQD للرسالة المستلمة في اليوم. لإلغاء الإشتراك أرسل UGL الى 3368</p>) : (<p>Zain Subscribers will enjoy 1 day free trial. Service price is 240 IQD Daily. To unsubscribe send UGL to 3368</p>)
          }
        </div>
      </div>

    </div>

  );
}

export default LandingPageHeaderFlow;